<template>
  <div class="shelf-add" v-loading="loading">
    <el-form :rules="formRule"
             :inline="true"
             ref="formRef"
             :model="shelfInfo"
             class="shelf-form"
             label-position="right">
      <el-form-item label="货架名称" prop="shelfName" class="shelf-form-shelf-name">
        <el-input v-model.lazy="shelfInfo.shelfName" :placeholder="placerholder.shelfName" v-elInput clearable
                  maxlength="10"/>
      </el-form-item>
      <el-form-item label="备注" class="shelf-form-remark" prop="remark">
        <el-input
            v-model="shelfInfo.remark"
            :placeholder="placerholder.shelfRemark"
            v-elInput
            maxlength="50"
        />
      </el-form-item>
    </el-form>
    <el-scrollbar :height="height-280" always>
      <div class="shelf-child-list-content">
        <shelfList :shelf-info="shelfInfo">
          <template #default="{data}">
            <div class="shelf-child-list-content-item">
              <div class="shelf-child-list-content-item-title">{{ data.childShelfName }}</div>
              <el-checkbox v-model="data.status" class="shelf-child-list-content-item-checkbox"/>
            </div>
          </template>
        </shelfList>
      </div>
    </el-scrollbar>
    <shelf-footer @close="close" @submit="submit"></shelf-footer>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import useShelfControl from "@utils/hooks/shelf/useShelfControl";
import {rules} from '@/utils/config/ruleConfig'
import useStock from "@utils/hooks/useStock";
import placerholder from '@/utils/config/placerholder'
import shelfList from "@views/personal/shelfManagement/component/shelfList/index.vue";
import {useViewPort} from "@utils/tool/useVIewPort";
import {useRoute, useRouter} from "vue-router";
import useShelfDetail from "@utils/hooks/shelf/useShelfDetail";
import ShelfFooter from '@/views/personal/shelfManagement/component/footer/index.vue'

const loading = ref(false)
const route = useRoute()
const router = useRouter()
const {height} = useViewPort()
const {stockList, getStockListData} = useStock()
const formRef = ref(null)
const formRule = reactive({
  stockId: rules.stockId,
  shelfName: rules.shelfName,
  remark: rules.remark,
  column: rules.column,
  row: rules.row
})
const {shelfInfo, handleEditShelf, setShelfInfo} = useShelfControl()
const {shelfDetail, getShelfDetail} = useShelfDetail()
const shelfId = route.query.id
const stockId = route.query.stockId
const submit = async () => {
  const form = formRef.value
  if (!form) return
  await form.validate(async (valid, fields) => {
    if (valid) {
      if (await handleEditShelf()) {
        router.back()
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const close = () => {
  router.back()
}

onMounted(async () => {
  loading.value = true
  await getShelfDetail({shelfId, stockId})
  setShelfInfo(shelfDetail)
  loading.value = false
})

//查看效果
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "updateShelf",
});
</script>

<style lang="scss" scoped>
@import "../../common.scss";

.shelf-list-child-name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
}
</style>
